import React from 'react';
import { Link } from 'react-router-dom';
import './Home.css';
import HOMEHERO from '../images/HOMEHERO.webp'; // Replace with the actual path to your image
import WASHERMAN from '../images/WASHERMAN.jpg'; // Replace with the actual path to your image
import FRIDGEMAN from '../images/FRIDGEMAN.jpg'; // Replace with the actual path to your image

function Home() {
  return (
    <div className="home">
      <section id="hero" style={{ backgroundImage: `url(${HOMEHERO})` }}>
        <div className="hero-content">
          <h1>Home Appliance Service</h1>
          <p>"Hari Appliance Care – Trusted Care for Every Repair!"</p>
          <a href="tel:+918489997666" className="hero-btn">Book Service?</a>
        </div>
      </section>

      <section id="about">
        <div className="container">
          <h2>About Hari Appliance Care</h2>
          <div className="row">
            <div className="card">
              <div className="card-icon">
                <i className="fas fa-tools"></i>
              </div>
              <div className="card-content">
                <h3>Washing Machine Repair</h3>
                <p>Expert service for all washing machine brands. Reliable, quick, and efficient.</p>
              </div>
            </div>

            <div className="card">
              <div className="card-icon">
                <i className="fas fa-snowflake"></i>
              </div>
              <div className="card-content">
                <h3>Fridge Repair</h3>
                <p>Comprehensive fridge repair for all brands and models.</p>
              </div>
            </div>

            <div className="card">
              <div className="card-icon">
                <i className="fas fa-shield-alt"></i>
              </div>
              <div className="card-content">
                <h3>Multi-Brand Service</h3>
                <p>We service appliances from any brand with unmatched expertise.</p>
              </div>
            </div>

            <div className="card">
              <div className="card-icon">
                <i className="fas fa-calendar-check"></i>
              </div>
              <div className="card-content">
                <h3>12+ Years Experience</h3>
                <p>Over a decade of trusted service in appliance repair.</p>
              </div>
            </div>
          </div>
        </div>
      </section>





      <section className="description-section">

{/* First Row: Washing Machine Service */}
<div className="description-row">
  <div className="description-image">
    <img src={WASHERMAN} alt="Washing Machine" />
  </div>
  <div className="description-content">
    <h3>Washing Machine Service</h3>
    <p>
      <strong>Hari Appliance Care</strong> offers top-quality washing machine repair and service for all brands. Our expert technicians provide reliable solutions to keep your washing machine running smoothly.
    </p>
  </div>
</div>

{/* Second Row: Fridge Service */}
<div className="description-row">
  <div className="description-image">
    <img src={FRIDGEMAN} alt="Fridge" />
  </div>
  <div className="description-content">
    <h3>Fridge Service</h3>
    <p>
      <strong>Hari Appliance Care</strong> is your trusted partner for refrigerator repair and maintenance. We handle all fridge brands with precision and care to ensure optimal performance.
    </p>
  </div>
</div>

</section>


      <section id="we-are-the-best">
  <div className="container">
    <h2>We Are the Best</h2>
    <div className="row">
      <div className="card">
        <div className="card-icon">
          <i className="fas fa-wrench"></i>
        </div>
        <div className="card-content">
          <h3>Expert Washing Machine Service</h3>
          <p>Specialized in solving all washing machine issues efficiently.</p>
        </div>
      </div>

      <div className="card">
        <div className="card-icon">
          <i className="fas fa-thermometer-half"></i>
        </div>
        <div className="card-content">
          <h3>Reliable Fridge Service</h3>
          <p>Ensuring your fridge functions like new with our premium repair solutions.</p>
        </div>
      </div>

      <div className="card">
        <div className="card-icon">
          <i className="fas fa-star"></i>
        </div>
        <div className="card-content">
          <h3>Trusted by Thousands</h3>
          <p>Our customers trust us for our quality service and timely solutions.</p>
        </div>
      </div>
    </div>
  </div>
</section>









<section id="how-we-get-customers">
  <div className="container">
    <h2>How We Get Customers</h2>
    <div className="row">
      <div className="card">
        <div className="card-icon">
          <i className="fas fa-thumbs-up"></i>
        </div>
        <div className="card-content">
          <h3>Trustable Service Center</h3>
          <p>Our reputation is built on trust and high-quality service.</p>
        </div>
      </div>

      <div className="card">
        <div className="card-icon">
          <i className="fas fa-globe"></i>
        </div>
        <div className="card-content">
          <h3>Website & Google Ads</h3>
          <p>We connect with customers through our website and Google Ads promotion.</p>
        </div>
      </div>

      <div className="card">
  <div className="card-icon">
    ₹
  </div>
  <div className="card-content">
    <h3>Low Service Charge</h3>
    <p>Our service starts at just ₹499, with spare parts charged separately.</p>
  </div>
</div>


      <div className="card">
        <div className="card-icon">
          <i className="fas fa-user-check"></i>
        </div>
        <div className="card-content">
          <h3>Transparent Work</h3>
          <p>We keep customers informed about every step of the repair process.</p>
        </div>
      </div>

      <div className="card">
        <div className="card-icon">
          <i className="fas fa-shield-alt"></i>
        </div>
        <div className="card-content">
          <h3>Warranty & Guarantee</h3>
          <p>We offer a 3–6 month warranty on services and spare parts.</p>
        </div>
      </div>
    </div>
  </div>
</section>


<div className="card">
  <div className="card-icon">
    <i className="fas fa-clock"></i>
  </div>
  <div className="card-content">
    <h3>Opening Hours</h3>
    <ul className="hours-list">
      <li><strong>Monday:</strong> 8:00 AM - 8:00 PM</li>
      <li><strong>Tuesday:</strong> 8:00 AM - 8:00 PM</li>
      <li><strong>Wednesday:</strong> 8:00 AM - 8:00 PM</li>
      <li><strong>Thursday:</strong> 8:00 AM - 8:00 PM</li>
      <li><strong>Friday:</strong> 8:00 AM - 8:00 PM</li>
      <li><strong>Saturday:</strong> 8:00 AM - 8:00 PM</li>
      <li><strong>Sunday:</strong> Holiday</li>
    </ul>
  </div>
</div>

<div className="card disclaimer-card">
  <div className="card-icon">
    <i className="fas fa-exclamation-circle"></i>
  </div>
  <div className="card-content">
    <h3>Disclaimer</h3>
    <p>
      <strong>Hari Appliance Care</strong> is not an authorized service center. We are a multibrand service center and can provide service for any brand. However, please note that we are not authorized by any brand for their services.
    </p>
    <p>
      Our services are independent and we provide repairs, maintenance, and support for various home appliances across brands.
    </p>
  </div>
</div>


    </div>
  );
}

export default Home;
