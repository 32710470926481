// src/pages/About.js
import React from 'react';
import './About.css';

function About() {
  return (
    <>
    
    <section className="intro-section">
        <div className="intro-content">
          <h1>Welcome to Hari Appliance Care</h1>
          <p>
            At <strong>Hari Appliance Care</strong>, we are committed to providing the best home appliance repair services. Our expert technicians are dedicated to delivering fast, reliable, and affordable solutions for all major brands of washing machines, refrigerators, air conditioners, and other home appliances.
          </p>
        </div>
      </section>

      {/* Section: About Us */}
      <section className="about-us-section">
        <div className="about-content">
          <h2>About Hari Appliance Care</h2>
          <p>
            <strong>Hari Appliance Care</strong> is a trusted name in home appliance service. Established with the aim of providing high-quality repair and maintenance services, we specialize in servicing a wide range of appliances across various brands. Whether it's a washing machine that needs fixing or a refrigerator that’s acting up, our team is ready to serve you. 
          </p>
          <p>
            Our technicians are highly trained, certified, and equipped with the latest tools to diagnose and repair your appliances with efficiency. We value customer satisfaction and ensure transparent pricing, prompt service, and guaranteed results. Over the years, we have built a reputation for reliability, integrity, and professionalism in the appliance service industry.
          </p>
        </div>
      </section>

      {/* Section: Our Services */}
      <section className="services-section">
        <div className="services-content">
          <h2>Our Services</h2>
          <div className="service-list">
            <div className="service-item">
              <h3>Washing Machine Service</h3>
              <p>
                We offer expert washing machine repair services for all major brands. Whether it's an issue with the motor, drum, or any other part, our team has the expertise to get your washing machine working like new.
              </p>
            </div>
            <div className="service-item">
              <h3>Refrigerator Service</h3>
              <p>
                Our fridge repair services cover all types, from minor repairs to complex issues like compressor failure. We ensure your refrigerator runs efficiently, keeping your food fresh for longer.
              </p>
            </div>
            {/* <div className="service-item">
              <h3>AC Repair and Maintenance</h3>
              <p>
                Whether it’s a minor AC issue or a major breakdown, our technicians are skilled in servicing all kinds of air conditioners. We offer regular maintenance services to keep your AC running smoothly all year round.
              </p>
            </div>
            <div className="service-item">
              <h3>Microwave and Oven Repair</h3>
              <p>
                From simple fixes to complicated problems, our team provides comprehensive microwave oven repair services. We handle everything from power issues to faulty components.
              </p>
            </div> */}
          </div>
        </div>
      </section>

      {/* Section: Our Values */}
      <section className="values-section">
        <div className="values-content">
          <h2>Our Values</h2>
          <p>
            At Hari Appliance Care, we are driven by a commitment to quality and customer satisfaction. Our core values include:
          </p>
          <ul>
            <li><strong>Customer Focus:</strong> We place our customers at the heart of everything we do. Your satisfaction is our top priority.</li>
            <li><strong>Integrity:</strong> We maintain transparency in our services, providing clear communication and fair pricing.</li>
            <li><strong>Quality:</strong> We provide high-quality repairs using the best parts and technology to ensure long-lasting results.</li>
            <li><strong>Reliability:</strong> You can trust us to deliver consistent, efficient, and dependable services every time.</li>
          </ul>
        </div>
      </section>

      {/* Section: Contact Us */}
      {/* <section className="contact-section">
        <div className="contact-content">
          <h2>Contact Hari Appliance Care</h2>
          <p>
            If you're looking for professional appliance repair services, don't hesitate to reach out to us. Our team is ready to assist you with any appliance issue, big or small. Contact us today to schedule a service!
          </p>
          <form className="contact-form">
            <input type="text" placeholder="Your Name" required />
            <input type="email" placeholder="Your Email" required />
            <textarea placeholder="Describe your issue" required></textarea>
            <button type="submit">Submit</button>
          </form>
        </div>
      </section> */}

    
    </>
    
  );
}

export default About;
