import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-container">
  {/* Column 1: Home Appliance Info */}
  <div className="footer-column">
    <h3>Hari Appliance care</h3>
    <p>Your trusted partner for all appliance repairs and services. We are dedicated to offering high-quality repair services for all your home appliances.</p>
  </div>

  {/* Column 2: Navigation Links */}
  <div className="footer-column">
    <h3>Quick Links</h3>
    <ul>
      <li><Link to="/">Home</Link></li>
      <li><Link to="/services/washing-machine">Washing Machine</Link></li>
      <li><Link to="/services/fridge">Fridge</Link></li>
      <li><Link to="/about">About</Link></li>
    </ul>
  </div>

  {/* Column 3: Social Media */}
  <div className="footer-column">
    <h3>Follow Us</h3>
    <div className="social-icons">
      <a href="https://wa.me/8489997666" target="_blank" rel="noopener noreferrer">
        <i className="fab fa-whatsapp"></i> WhatsApp
      </a>
      <a href="mailto:youremail@example.com" target="_blank" rel="noopener noreferrer">
        <i className="fas fa-envelope"></i> Email
      </a>
    </div>
  </div>

  {/* Column 4: Map */}
  <div className="footer-column">
    <h3>Our Location</h3>
    <div className="map">
    <iframe
  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6133.065270234042!2d80.2798303581227!3d13.08784444625232!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a5267e4b395c3b3%3A0x8d539ed070d44de5!2sChennai%2C%20Tamil%20Nadu!5e0!3m2!1sen!2sin!4v1601239489519!5m2!1sen!2sin"
  width="100%" 
  height="400" 
  frameBorder="0" 
  style={{ border: 0 }} 
  allowFullScreen 
  aria-hidden="false" 
  tabIndex="0">
</iframe>
    </div>
  </div>

  {/* Column 5: Address */}
  <div className="footer-column">
    <h3>Our Address</h3>
    <p>
    284, 2nd Cross Street, Eswari Nagar, Nagalkeni, Chromepet, Kancheepuram,Tami Nadu-600044
    </p>
  </div>
</div>



      

      {/* Privacy Policy, Terms and Conditions Links */}
      <div className="footer-bottom">
        <p>&copy; {new Date().getFullYear()} Hari Appliance Care. All Rights Reserved.</p>
        <div className="footer-links">
          <Link to="/privacy-policy">Privacy Policy</Link> | 
          <Link to="/terms-and-conditions"> Terms & Conditions</Link>
        </div>
        {/* Disclaimer Section */}
     
      </div>
      {/* <div className="footer-disclaimer">
        <p><strong>Disclaimer:</strong> HomeAppliance Create is not responsible for any damages caused during the appliance repair services. Our team ensures the highest quality of work, but liability is limited to the scope of the service provided.</p>
      </div> */}
    </footer>
  );
}

export default Footer;
